<script setup lang="ts">
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { Navigation } from '@/router/navigation';
    import Button from 'primevue/button';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { computed, watch } from 'vue';
    import { isProductUsed } from '@/util/isProductUsed';
    import ChippedContent from '@/components/ChippedContent.vue';
    import ProductImage from '@/components/ProductImage.vue';
    import { RouterLink } from 'vue-router';
    import { isDefined } from '@containex/common-utils';
    import { useI18n } from 'vue-i18n';
    import DepotLocationWithIcon from '@/components/DepotLocationWithIcon.vue';
    import ProductAvailability from '@/components/ProductAvailability.vue';
    import PriceInformation from '@/components/PriceInformation.vue';
    import { filterProductVariantForProvisionType } from '@/util/filterProductVariantForProvisionType';
    import { getAvailabilityForVariantBasedOnCart } from '@/util/variantAvailability';
    import { Availability, type MarketDto } from '@containex/portal-backend-dto';
    import { optimalVariantForMarket } from '@/product/common/util/optimal-variant-for-market';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { VIRTUAL_RENTAL_DEPOT } from '@containex/portal-business-logic';

    const props = defineProps<{
        product: CtxProduct;
        currentRegionId?: string;
        postalCode?: string;
        isRental: boolean;
        isRentalDurationSet: boolean;
        market: MarketDto;
    }>();

    const emits = defineEmits<{
        addToCart: [product: CtxProduct, variantIdToAdd: string];
        showPrice: [];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { cart } = useCartQuery();
    const cartAction = useCartAction();

    const currentVariant = computed(() => {
        if (props.isRental) {
            // fix for CPP-806
            // there are no prices set for rental products if there's no zip code given, therefore optimalVariantForMarket does not find a variant.
            // due to data quality (prices for regions) there might not be a price despite a zip code either, therefore we handle rental products different under the premise of rental products only having one variant
            const filteredVariants = props.product.variants.filter(
                (variant) =>
                    filterProductVariantForProvisionType(variant, props.market.code, props.isRental) &&
                    variant.depotQuantity.depot.depot_id === VIRTUAL_RENTAL_DEPOT
            );
            return filteredVariants[0];
        }
        return optimalVariantForMarket(props.product.variants, props.market.code, props.isRental);
    });

    const productLink = computed(() => {
        const name = props.isRental ? Navigation.RentalProductDetail : Navigation.SalesProductDetail;

        return {
            name,
            params: {
                id: props.product.id,
                variantId: currentVariant.value?.id,
            },
        };
    });

    const minProductPrice = computed(() => {
        if (currentVariant.value?.original_price != null) {
            return currentVariant.value.original_price;
        }

        const filteredPrices = (props.product.variants ?? [])
            .map((variant) => variant.original_price)
            .filter(isDefined);

        return Math.min(...filteredPrices);
    });

    const variantIfNothingIsAvailable = computed(() =>
        props.product.variants.find((i) => i.original_price === minProductPrice.value)
    );

    const currentDepotType = computed(() => {
        if (
            currentVariant.value?.depotQuantity.depot.type != null ||
            variantIfNothingIsAvailable.value?.depotQuantity.depot.type != null
        ) {
            return (
                currentVariant.value?.depotQuantity.depot.type ??
                variantIfNothingIsAvailable.value?.depotQuantity.depot.type
            );
        }
        return null;
    });

    const currentDepotLocation = computed(() => {
        if (
            currentVariant.value?.depotQuantity.depot.address.city != null ||
            variantIfNothingIsAvailable.value?.depotQuantity.depot.address.city != null
        ) {
            return (
                currentVariant.value?.depotQuantity.depot.address.city ??
                variantIfNothingIsAvailable.value?.depotQuantity.depot.address.city
            );
        }
        return null;
    });

    const currentAvailability = computed(() => {
        if (currentVariant.value != null) {
            return getAvailabilityForVariantBasedOnCart(currentVariant.value, cart.value, true);
        }
        return Availability.NotAvailable;
    });

    const currentVariantCompletionDate = computed(() => currentVariant.value?.depotQuantity.completion_date);
    const currentVariantOnDemandCompletionDate = computed(
        () => currentVariant.value?.depotQuantity.on_demand_completion_date
    );

    const formattedProductPrice = computed(() => formatPrice(minProductPrice.value));

    const additionalDepotVariants = computed(() => {
        const variants = props.product.variants ?? [];
        const variantsForMarket = variants.filter((variant) => {
            return filterProductVariantForProvisionType(variant, props.market.code, props.isRental);
        });
        return (
            variantsForMarket
                .map((variant) => getAvailabilityForVariantBasedOnCart(variant, cart.value, true))
                .filter((variantAvailability) => variantAvailability !== Availability.NotAvailable).length - 1
        );
    });

    let isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(currentVariant.value));

    watch(cart, () => {
        isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(currentVariant.value));
    });
</script>

<template>
    <RouterLink class="link-reset grid-item" :to="productLink">
        <div class="product">
            <div class="product-image-wrapper">
                <ChippedContent :is-used="isProductUsed(product)" :show-chip="!isRental">
                    <template #content>
                        <ProductImage
                            :url="product.thumbnail ?? undefined"
                            :alt="product.title ?? ''"
                            class="product-image"
                        />
                    </template>
                </ChippedContent>
            </div>
            <div class="product-content">
                <h2 class="text-xl-bold-line-height-auto">{{ product.title }}</h2>
                <div class="depot-city">
                    <template v-if="!isRental">
                        <template v-if="currentDepotType != null">
                            <DepotLocationWithIcon
                                :depot-type="currentDepotType"
                                :depot-location="currentDepotLocation"
                                :additional-locations="additionalDepotVariants"
                            />
                        </template>
                    </template>
                </div>
                <div v-if="!isRental" class="product-availability">
                    <ProductAvailability
                        :product-available="currentAvailability"
                        :completion-date="currentVariantCompletionDate"
                        :on-demand-completion-date="currentVariantOnDemandCompletionDate"
                    />
                </div>
                <div v-if="currentRegionId != null && postalCode != null" class="product-details">
                    <p class="product-quantity"></p>
                    <p>
                        <span class="product-price font-bold">
                            <span v-if="isRental">
                                <span v-if="!isRentalDurationSet">
                                    {{ t('PRODUCT.PRICE_FROM') }}
                                </span>
                                {{ t('PRODUCT.PRICE_DAILY', { price: formattedProductPrice }) }}
                            </span>
                            <span v-else>
                                {{ formattedProductPrice }}
                            </span>
                        </span>
                        <template v-if="currentDepotType != null && !isRental">
                            <PriceInformation
                                class="price-information-details"
                                :depot-type="currentDepotType"
                                :depot-location="currentDepotLocation"
                            />
                        </template>
                        <span class="product-tax">{{ t('PRODUCT.EXCLUSIVE_TAX') }}</span>
                    </p>
                </div>
                <Button
                    v-if="currentRegionId != null && postalCode != null && minProductPrice > 0"
                    icon="pi pi-cart-plus"
                    icon-pos="left"
                    :disabled="currentAvailability === Availability.NotAvailable || !isVariantAvailableForCart"
                    :label="t('CART.ADD_TO_CART')"
                    class="cta-button"
                    rounded
                    @click.prevent="
                        emits('addToCart', product, currentVariant?.id ?? variantIfNothingIsAvailable?.id ?? '')
                    "
                />
                <Button
                    v-if="currentRegionId == null || postalCode == null"
                    :label="t('PRODUCT.SHOW_PRICE_BUTTON')"
                    class="cta-button product-list-item-show-price-button"
                    rounded
                    severity="secondary"
                    @click.prevent="$emit('showPrice')"
                />
            </div>
        </div>
    </RouterLink>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .link-reset {
        all: unset; // reset link styles
    }

    .grid-item {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .product {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid var(--surface-d);
        overflow: hidden;
        min-width: 100%;
    }

    @include main.for-breakpoint-md {
        .product {
            max-width: min(300px, 100%);
            min-width: min(300px, 100%);
        }
    }

    @include main.for-breakpoint-lg {
        .product {
            max-width: min(282px, 100%);
            min-width: min(282px, 100%);
        }
    }

    .product-content {
        padding: main.$spacing-5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .product-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .product-price {
        margin-top: main.$spacing-4;
        display: block;
        text-align: right;
        font-size: main.$font-size-lg;
        font-weight: 700;
    }

    .price-information-details {
        text-align: right;
        font-size: main.$font-size-sm;
    }

    .product-tax {
        display: block;
        text-align: right;
        font-size: main.$font-size-sm;
        color: main.$color-secondary;
    }

    .depot-city {
        display: flex;
        gap: main.$spacing-2;
        align-items: center;
        margin-top: main.$spacing-4;
    }

    .product-availability {
        margin-top: main.$spacing-1;
        flex-grow: 1;
    }

    .product-list-item-show-price-button {
        margin-top: main.$spacing-5;
    }

    .product-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 200px;
    }

    .chippedContent {
        width: 100%;
    }

    .product-image {
        width: 100%;
        height: 200px;
        border-radius: 4px 4px 0 0;
        padding-top: main.$spacing-3;
    }

    .cta-button {
        display: inline-block;
        width: 100%;
        font-weight: 500;
    }
</style>
